export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"; 
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const GOOGLE_USER_SIGNIN_REQUEST = 'GOOGLE_USER_SIGNIN_REQUEST';
export const GOOGLE_USER_SIGNIN_SUCCESS = 'GOOGLE_USER_SIGNIN_SUCCESS';
export const GOOGLE_USER_SIGNIN_FAIL = 'GOOGLE_USER_SIGNIN_FAIL';

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const USER_PROFILE_RESET = "USER_PROFILE_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const EMAIL_CONFIRMATION_REQUEST = 'EMAIL_CONFIRMATION_REQUEST';
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_FAIL = 'EMAIL_CONFIRMATION_FAIL';

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL';

export const RESET_EMAIL_REQUEST = 'RESET_EMAIL_REQUEST';
export const RESET_EMAIL_SUCCESS = 'RESET_EMAIL_SUCCESS';
export const RESET_EMAIL_FAIL = 'RESET_EMAIL_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';