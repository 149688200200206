const blogs = [
    {
      _id: "1",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Do you ever wonder why some apps and websites seem to effortlessly guide you through their features, while others leave you feeling lost and frustrated? The secret lies in mastering the art of user flow optimization. In this quick 5-minute read, we'll explore the key concepts and practical tips to create seamless user experiences that keep your audience coming back for more.",
      read: "1",
    },
    { _id: "2",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "3",
    },
    { _id: "3",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "5",
    },
    { _id: "4",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "2",
    },
    {
      _id: "5",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "6",
    },
    { _id: "6",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "4",
    },
    { _id: "7",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "6",
    },
    { _id: "8",
      heroImage: "/img/blog1.png",
      innerImage: "/img/blog1-details.png",
      title: "Mastering the Art of User Flow Optimization",
      excerpt: "Learn how top digital products enhance user experiences through meticulously designed user flows. Explore best practices, case studies, and expert insights in the world of UX optimization.",
      read: "3",
    }
  ];
  
  export default blogs;
  