import React from 'react'
import Header from '../components/Header'

const Faqs = () => {
  return (
    <>
      <Header />
      <div>Faqs</div>
    </>
  )
}

export default Faqs